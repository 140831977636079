<template>
  <div id="shipping">
    <section class="destination mb-4" v-if="addressComplete == false && !cartHasOnlyGear() && !cartHasOnlySamples()">
      <div class="box-header mb-2">Shipping Destination<br></div>
      <div class="border-bottom-rows">
        <div class="table-row g-4">
          <div class="select f-1"><input type="radio" name="destination" value="freight" v-model="shippingDestination" /></div>
          <div class="col f-5">Freight and Parcel Shipping</div>
        </div>
        <div class="table-row g-4">
          <div class="select f-1"><input type="radio" name="destination" value="Port" v-model="shippingDestination" /></div>
          <div class="col f-5">Ship To Port For Ocean Transit</div>
        </div>
        <div class="table-row g-4">
          <div class="select f-1"><input type="radio" name="destination" value="Own Arrangements" v-model="shippingDestination" /></div>
          <div class="col f-5">Pickup or Make Own Arrangements</div>
        </div>
      </div>
    </section>

    <section class="pickup-options mb-4" v-if="shippingDestination == 'Own Arrangements'">
      <div class="product-table-no-vue">
        <div class="table-header g-4">
          <div class="select f-1"></div>
          <div class="col product f-4">Option</div>
          <div class="col text-center">Available For Pickup</div>
          <div class="col text-center">Price</div>
        </div>
        <div class="border-bottom-rows">
          <div class="table-row g-4">
            <div class="select f-1"><input type="radio" name="shippingOption" @click="$store.commit('setShippingMethod', { name: 'Pickup At Plant', price: 0.00, shippingDetails: {} })" /></div>
            <div class="col product text-uppercase font-weight-bold f-4">Pickup At Plant (Between 730AM and 330PM PST)</div>
            <div class="col text-center f-1">1 day</div>
            <div class="col text-center f-1">$0.00</div>
          </div>
          <div class="table-row g-4">
            <div class="select f-1"><input type="radio" name="shippingOption" @click="$store.commit('setShippingMethod', { name: 'Arrange Shipping', price: 0.00, shippingDetails: {} })" /></div>
            <div class="col product text-uppercase font-weight-bold f-4">Arrange Shipping</div>
            <div class="col text-center f-1">N/A</div>
            <div class="col text-center f-1">$0.00</div>
          </div>
        </div>
      </div>
    </section>
    
    <section class="address mb-4" v-if="(shippingDestination != null && addressComplete == false && shippingDestination != 'Own Arrangements') || ($store.state.orderInfo.shippingDestination == 'Own Arrangements' && $store.state.orderInfo.shippingMethod != null && $store.state.orderInfo.shippingMethod.name == 'Arrange Shipping')">
      <div class="box-header mb-3">Shipping Address</div>
      <div class="content mb-3" v-if="$store.state.shippingAddresses.length > 0">
        <h4 class="sohne-breit">Existing Addresses</h4>
        <div class="underlined-table-rows">
          <AddressLine v-for="address in $store.state.shippingAddresses" v-bind:key="address.id" :address="address" @address-selected="addressSelected"></AddressLine>
          <div class="address-line g-4">
            <div class="select">
              <input type="radio" name="address" class="mr-2" v-model="$store.state.orderInfo.shippingAddress" :value="null" />
            </div>
            <div class="label">
              <h5 class="mb-0">Add New Address</h5>
            </div>
          </div>
        </div>

        <div class="form-item mt-3" v-if="$store.state.orderInfo.shippingAddress != null && shippingDestination != 'Own Arrangements'">
          <button class="button get-shipping-rates" @click="getShippingRates()">Get Shipping Rates</button>
        </div>
      </div>
      <div class="content" v-if="$store.state.orderInfo.shippingAddress == null">
        <h4 class="sohne-breit">New Address</h4>
        <form ref="form" @submit.prevent="saveNewAddress" class="form">
          <div class="form-item">
            <label>Is this a residential address?</label>
            <div class="d-flex align-items-center justify-content-start yes-no"><input type="radio" class="mr-1" name="isResidential" v-model="newShippingAddress['residential']" value="1" required /> Yes <input type="radio" class="ml-2 mr-1" name="isResidential" v-model="newShippingAddress['residential']" value="0" required /> No</div>
          </div>
          <div class="form-item red-links" v-if="isResidential == 'yes'">
            <p>We cannot ship to residential address. Please contact <a href="tel:5034859026">503.485.9026</a> if you have further questions.</p>
          </div>
          <div class="form" v-else>
            <div class="form-item">
              <label>Country</label>
              <select v-model="newShippingAddress['country']" required>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
              </select>
            </div>
            <div class="form-item half">
              <label>Name</label>
              <input type="text" v-model="newShippingAddress['name']" required />
            </div>
            <div class="form-item half">
              <label>Company</label>
              <input type="text" v-model="newShippingAddress['company']" required />
            </div>
            <div class="form-item half">
              <label>Address</label>
              <input type="text" v-model="newShippingAddress['address1']" required />
            </div>
            <div class="form-item half">
              <label>Address Line 2</label>
              <input type="text" v-model="newShippingAddress['address2']" />
            </div>
            <div class="form-item half">
              <label>City</label>
              <input type="text" v-model="newShippingAddress['city']" required />
            </div>
            <div class="form-item half" v-if="newShippingAddress['country'] != 'CA'">
              <label>State</label>
              <select v-model="newShippingAddress['state']" required>
                <option value="">Choose State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="GU">Guam</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VI">Virgin Islands</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="form-item half" v-if="newShippingAddress['country'] == 'CA'">
              <label>Province</label>
              <select v-model="newShippingAddress['state']" required>
                <option value="">Choose Province</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="YT">Yukon</option>
              </select>
            </div>
            <div class="form-item half">
              <label v-html="newShippingAddress.country == 'CA' ? '6 Digit Postal Code (xxxxxx)' : '5 Digit Zipcode (xxxxx)'"></label>
              <input type="text" v-model="newShippingAddress['zipcode']" required :maxlength="newShippingAddress.country == 'CA' ? 6 : 5" />
            </div>
            <div class="form-item half">
              <label>Phone</label>
              <input type="text" v-model="newShippingAddress['phone']" required />
            </div>

            <!-- Canada -->
            <div class="form-item" v-if="newShippingAddress['country'] == 'CA'">
              <label>Tax ID</label>
              <input type="text" v-model="newShippingAddress['tax_id']" required />
            </div>

            <h4 class="form-item mt-3" v-if="newShippingAddress['country'] == 'CA'">Customs Broker</h4>
            <div class="form-item half" v-if="newShippingAddress['country'] == 'CA'">
              <label>Customs Broker Name</label>
              <input type="text" v-model="newShippingAddress['customs_broker_name']" />
            </div>
            <div class="form-item half" v-if="newShippingAddress['country'] == 'CA'">
              <label>Customs Broker Phone</label>
              <input type="text" v-model="newShippingAddress['customs_broker_phone']" />
            </div>

            <!-- Port -->
            <h4 class="form-item mt-3" v-if="shippingDestination == 'Port'">Port Info</h4>
            <div class="form-item half" v-if="shippingDestination == 'Port'">
              <label>Ocean Booking Number</label>
              <input type="text" v-model="newShippingAddress['ocean_booking_number']" />
            </div>

            <div class="form-item mt-3">
              <input type="submit" class="button red-bg" value="Save Address" />
            </div>
          </div>
        </form>
      </div>
    </section>
    <section class="shipping-options" v-if="cartHasOnlyGear() && addressComplete">
      <div class="product-table-no-vue">
        <div class="table-header">
          <div class="col select f-1"></div>
          <div class="col product f-4">Shipping Provider</div>
          <div class="col text-center">Est. Delivery</div>
          <div class="col text-center">Price</div>
        </div>
        <div class="table-row">
          <div class="col select f-1"><input type="radio" name="shippingProvider" value="fedex" @click="$store.commit('setShippingMethod', { name: 'Fedex Ground', price: '8.00', time: '5-7 business days' })" /></div>
          <div class="col product text-uppercase font-weight-bold f-3">Fedex Ground</div>
          <div class="col text-center f-1">5-7 Business Days</div>
          <div class="col text-center f-1">$8.00</div>
        </div>
      </div>
    </section>
    <section class="shipping-options" v-if="cartHasOnlySamples() && addressComplete">
      <div class="product-table-no-vue">
        <div class="table-row">
          <div class="col select f-1"><input type="radio" name="shippingProvider" value="fedex" @click="$store.commit('setShippingMethod', { name: 'Free Shipping', price: '0.00', time: '5-7 business days' })" /></div>
          <div class="col product text-uppercase font-weight-bold f-3">Free Shipping</div>
          <div class="col text-center f-1">5-7 Business Days</div>
          <div class="col text-center f-1">$0.00</div>
        </div>
      </div>
    </section>
    <section class="shipping-options" v-if="!cartHasOnlyGear() && !cartHasOnlySamples() && shippingDestination != 'Own Arrangements' && addressComplete && foundRates == true">
      <div class="product-table-no-vue">
        <div class="box-header mb-2">Shipping Providers</div>
        <div class="underline-table-rows mb-3">
          <div class="table-row" v-if="shippingRates.fedexEconomy != null && $store.getters.pallets < 7">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.fedexEconomy.message }"><input type="radio" name="shippingProvider" value="fedexEconomy" @click="$store.commit('setShippingMethod', shippingRates.fedexEconomy)" /></div>
            <div class="col product f-3"><div class="name">{{ shippingRates.fedexEconomy.name }}</div><div class="time">{{ shippingRates.fedexEconomy.time }}</div></div>
            <div class="col text-center f-1" v-if="shippingRates.fedexEconomy.message == null">${{ parseFloat(shippingRates.fedexEconomy.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.fedexEconomy.message != null">{{ shippingRates.fedexEconomy.message }}</div>
          </div>
          <div class="table-row" v-if="shippingRates.fedexPriority != null && $store.getters.pallets < 7">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.fedexPriority.message }"><input type="radio" name="shippingProvider" value="fedexPriority" @click="$store.commit('setShippingMethod', shippingRates.fedexPriority)" /></div>
            <div class="col product f-3"><div class="name">{{ shippingRates.fedexPriority.name }}</div><div class="time">{{ shippingRates.fedexPriority.time }}</div></div>
            <div class="col text-center f-1" v-if="shippingRates.fedexPriority.message == null">${{ parseFloat(shippingRates.fedexPriority.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.fedexPriority.message != null">{{ shippingRates.fedexPriority.message }}</div>
          </div>
          <div class="table-row" v-if="shippingRates.fedexGround != null && $store.getters.pallets < 7 && shippingRates.fedexGround.message == null">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.fedexGround.message }"><input type="radio" name="shippingProvider" value="fedexGround" @click="$store.commit('setShippingMethod', shippingRates.fedexGround)" /></div>
            <div class="col product f-3"><div class="name">{{ shippingRates.fedexGround.name }}</div><div class="time">{{ shippingRates.fedexGround.time }}</div></div>
            <div class="col text-center f-1" v-if="shippingRates.fedexGround.message == null">${{ parseFloat(shippingRates.fedexGround.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.fedexGround.message != null">{{ (shippingRates.fedexGround.message == 'Package 1 - Weight is missing or invalid. ') ? 'Not available - exceeds case limit.' : shippingRates.fedexGround.message }}</div>
          </div>
          <div class="table-row" v-if="shippingRates.oldDominion != null && $store.getters.pallets < 7">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.oldDominion.message }"><input type="radio" name="shippingProvider" value="oldDominion" @click="$store.commit('setShippingMethod', shippingRates.oldDominion)" /></div>
            <div class="col product f-3"><div class="name">{{ shippingRates.oldDominion.name }}</div><div class="time">{{ shippingRates.oldDominion.time }}</div></div>
            <div class="col text-center f-1" v-if="shippingRates.oldDominion.message == null">${{ parseFloat(shippingRates.oldDominion.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.oldDominion.message != null">{{ shippingRates.oldDominion.message }}</div>
          </div>
          <div class="table-row" v-if="shippingRates.xpo != null && $store.getters.pallets < 7">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.xpo.message }"><input type="radio" name="shippingProvider" value="xpo" @click="$store.commit('setShippingMethod', shippingRates.xpo)" /></div>
            <div class="col product f-3"><div class="name">{{ shippingRates.xpo.name }}</div><div class="time">{{ shippingRates.xpo.time }}</div></div>
            <div class="col text-center f-1" v-if="shippingRates.xpo.message == null">${{ parseFloat(shippingRates.xpo.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.xpo.message != null">{{ shippingRates.xpo.message }}</div>
          </div>
          <div class="table-row" v-if="$store.getters.pallets >= 7">
            <div class="col select f-1"></div>
            <div class="col product f-3">Large Volume Order</div>
            <div class="col text-center f-3">Please contact our team at <a href="tel:9713040793">971-304-0793</a> for large volume orders.</div>
          </div>
          <div class="table-row" v-if="shippingRates.roadRunner != null">
            <div class="col select f-1" :class="{ 'opacity-0': shippingRates.roadRunner.message }"><input type="radio" name="shippingProvider" value="roadRunner" @click="$store.commit('setShippingMethod', shippingRates.roadRunner)" /></div>
            <div class="col product f-3">{{ shippingRates.roadRunner.name }}<br><span style="font-size: .85rem;">*Not used for time sensitive shipments</span></div>
            <div class="col text-center f-1" v-if="shippingRates.roadRunner.message == null">{{ shippingRates.roadRunner.time }}</div>
            <div class="col text-center f-1" v-if="shippingRates.roadRunner.message == null">${{ parseFloat(shippingRates.roadRunner.price).toFixed(2) }}</div>
            <div class="col text-center f-3" v-if="shippingRates.roadRunner.message != null">{{ shippingRates.roadRunner.message }}</div>
          </div>
        </div>
        <div class="content text-1 pt-2 pb-2">
          <p><strong>Please allow up to 3 business days for processing your order.</strong></p>
          <p>When selecting your freight option, please note that transit time does not include internal processing time, day of pickup, or weekends/holidays. We ship orders Monday-Friday. All orders ship FOB from Salem, OR. Orders are palletized and wrapped prior to shipping.</p>
          <p>Carriers require freight damage to be noted on the delivery receipt at the delivery time. Please note any transit damage on your delivery receipt and notify your Oregon Fruit rep immediately.</p>
        </div>
        <div class="box-header mb-2" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">Liftgate</div>
        <div class="table-row mb-3" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">
          <div class="col f-4">Do you need a liftgate with your shipment?</div>
          <div class="col text-center f-2">$45.00</div>
          <div class="col select radios f-1 yes-no">
            <input type="radio" name="liftgate" :value="true" v-model="liftgate" required> Yes
            <input type="radio" name="liftgate" :value="false" v-model="liftgate" required> No
          </div>
        </div>
        <div class="box-header mb-2" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">Delivery Appointment</div>
        <div class="table-row mb-3" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">
          <div class="col f-4">Is an appointment required for delivery at your destination?</div>
          <div class="col text-center f-2">$15.00</div>
          <div class="col select radios f-1 yes-no">
            <input type="radio" name="appointmentFee" :value="true" v-model="appointmentFee" required> Yes
            <input type="radio" name="appointmentFee" :value="false" v-model="appointmentFee" required> No
          </div>
        </div>
        <!-- Old Dominion Must Arrive-By Date -->
        <div class="box-header mb-2" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name == 'Old Dominion' && this.$store.state.userData.id == 1">Must Arrive-By Date (Old Dominion)</div>
        <div class="table-row mb-3" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name == 'Old Dominion' && this.$store.state.userData.id == 1">
          <div class="col f-4">Old Dominion offers must arrive-by dates for an additional charge. Would you like to use this service?</div>
          <div class="col text-center f-2">$50.00</div>
          <div class="col select radios f-1 yes-no">
            <input type="radio" name="oldDominionGuaranteedService" :value="true" v-model="oldDominionGuaranteedService" required> Yes
            <input type="radio" name="oldDominionGuaranteedService" :value="false" v-model="oldDominionGuaranteedService" required> No
          </div>
        </div>
        <div class="table-row mb-3" v-if="oldDominionGuaranteedService == true && this.$store.state.userData.id == 1">
          <div class="col f-4">Choose a guaranteed delivery date with Old Dominion.</div>
          <div class="col f-2 text-center">
            <input type="text" v-model="guaranteedServiceDate" placeholder="Enter date" />
          </div>
        </div>
        <!-- End XPO Guaranteed Delivery -->
        <!-- XPO Guaranteed Delivery -->
        <div class="box-header mb-2" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name == 'XPO' && this.$store.state.userData.id == 1">Guaranteed Service (XPO)</div>
        <div class="table-row mb-3" v-if="this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name == 'XPO' && this.$store.state.userData.id == 1">
          <div class="col f-4">XPO offers guaranteed service dates for an additional charge. Would you like to guarantee the date of delivery?</div>
          <div class="col text-center f-2">$75.00</div>
          <div class="col select radios f-1 yes-no">
            <input type="radio" name="xpoGuaranteedService" :value="true" v-model="xpoGuaranteedService" required> Yes
            <input type="radio" name="xpoGuaranteedService" :value="false" v-model="xpoGuaranteedService" required> No
          </div>
        </div>
        <div class="table-row mb-3" v-if="xpoGuaranteedService == true && this.$store.state.userData.id == 1">
          <div class="col f-4">Choose a guaranteed delivery date with XPO.</div>
          <div class="col f-2 text-center">
            <input type="text" v-model="guaranteedServiceDate" placeholder="Enter date" />
          </div>
        </div>
        <!-- End XPO Guaranteed Delivery -->
        <div class="box-header mb-2" v-if="shippingDestination == 'freight' && this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">
          Delivery Availability (required)
        </div>
        <div class="content text-1 mb-3" v-if="shippingDestination == 'freight' && this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground'">
          <textarea class="w-100 p-2" v-model="deliveryAvailability" placeholder="Days of week and times" required></textarea>
        </div>
        <div class="box-header mb-2">
          Shipping Notes
        </div>
        <div class="content text-1">
          <textarea class="w-100 p-2" v-model="shippingNotes"></textarea>
        </div>
      </div>
    </section>
    <section class="own-arrangements" v-if="$store.state.orderInfo.shippingMethod != null && $store.state.orderInfo.shippingMethod.name == 'Arrange Shipping'">
      <div class="box-header mb-2">Arranged Shipping Instructions</div>
      <div class="content mb-3">
        <form ref="form" class="form">
          <div class="form-item">
            <label>Who will you be shipping with? Provide information or reference numbers here if applicable.</label>
            <textarea class="w-100" v-model="arrangedInstructions" maxlength="80" required></textarea>
          </div>
        </form>
      </div>
    </section>
    <section class="own-arrangements" v-if="$store.state.orderInfo.shippingMethod != null && $store.state.orderInfo.shippingMethod.name == 'Pickup At Plant'">
      <div class="box-header mb-2">Pickup At Plant</div>
      <div class="content">
        <p><strong>Oregon Fruit Products</strong><br>3180 22nd St.<br>Salem, OR 97302</p>
        <form ref="form" class="form">
          <div class="form-item half">
            <label>Contact Phone Number</label>
            <input type="text" v-model="pickupPhone" required />
          </div>
          <div class="form-item half">
            <label>Pickup Date Time</label>
            <div style="display: flex; align-items: center;">
              <select v-model="pickupDate" required>
                <option value="">Date</option>
                <option v-for="date in pickupDates" :key="date">{{ date }}</option>
              </select>
              <select v-model="pickupTime" required>
                <option value="">Time</option>
                <option v-for="time in pickupTimes" :key="time">{{ convertMilitaryTime(time) }}</option>
              </select>
            </div>
            <!-- <input type="text" required v-model="pickupDatetime" placeholder="M-F 7:30AM-3:30PM Only" maxlength="30" /> -->
            <!-- <datetime minute-step="15" zone="America/Los_Angeles" type="datetime" :use12-hour="true" :min-datetime="twoDaysDate" v-model="pickupDatetime"></datetime> -->
          </div>
          <div class="form-item">
            <label>Comments</label>
            <textarea class="w-100" v-model="arrangedInstructions" maxlength="80" required></textarea>
          </div>
        </form>
      </div>
    </section>
    <a class="checkout button red-bg mt-3" @click="$store.commit('changeOrderStep', 'billing')" v-if="$store.state.orderInfo.shippingMethod != null && liftgateAddressed && deliveryAvailabilityAddressed">Continue To Billing</a>
    <section class="text-center text-1 font-weight-bold mt-3" v-if="$store.state.orderInfo.shippingMethod != null && (!liftgateAddressed || !deliveryAvailabilityAddressed)">You must complete all required fields before progressing.</section>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import moment from 'moment'
//import { Datetime } from 'vue-datetime'
import AddressLine from '@/components/fermentation/AddressLine.vue'
import GlobalMethods from '@/components/fermentation/GlobalMethods.js'

export default {
  name: 'Shipping',
  components: {
    //"datetime": Datetime,
    AddressLine
  },
  data() {
    return {
      shippingRates: {
        fedexEconomy: null,
        fedexPriority: null,
        fedexGround: null,
        roadRunner: null,
        oldDominion: null,
        xpo: null
      },
      foundRates: null,
      newShippingAddress: {
        type: 'shipping'
      },
      addressComplete: false,
      twoDaysDate: '',
      isResidential: 'no',
      pickupDate: '',
      pickupTime: ''
    };
  },
  watch: {
    shippingDestination(newVal) {
      this.newShippingAddress['country'] = newVal;
    },
    shippingRates: {
      handler(newVal) {
        if (newVal.fedex != null && newVal.roadRunner != null && newVal.oldDominion != null) {
          this.$overlay(false);
        }
      },
      deep: true
    },
    pickupDate() {
      this.pickupDatetime = this.pickupDate + ' ' + this.pickupTime;
    },
    pickupTime() {
      this.pickupDatetime = this.pickupDate + ' ' + this.pickupTime;
    }
  },
  computed: {
    liftgateAddressed() {
      if (this.shippingDestination == 'freight' && this.$store.state.orderInfo.shippingMethod != null && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground' && this.liftgate == null) {
        return false;
      }
      return true;
    },
    deliveryAvailabilityAddressed() {
      if (this.shippingDestination == 'freight' && this.deliveryAvailability == '' && this.$store.state.orderInfo.shippingMethod.name != 'Fedex Ground') {
        return false;
      }
      return true;
    },
    shippingDestination: {
      get() {
        return this.$store.state.orderInfo.shippingDestination;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'shippingDestination', value: value });
      }
    },
    deliveryAvailability: {
      get() {
        return this.$store.state.orderInfo.deliveryAvailability;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'deliveryAvailability', value: value });
      }
    },
    liftgate: {
      get() {
        return this.$store.state.orderInfo.liftgate;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'liftgate', value: value });
      }
    },
    appointmentFee: {
      get() {
        return this.$store.state.orderInfo.appointmentFee;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'appointmentFee', value: value });
      }
    },
    xpoGuaranteedService: {
      get() {
        return this.$store.state.orderInfo.xpoGuaranteedService;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'xpoGuaranteedService', value: value });
      }
    },
    oldDominionGuaranteedService: {
      get() {
        return this.$store.state.orderInfo.oldDominionGuaranteedService;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'oldDominionGuaranteedService', value: value });
      }
    },
    guaranteedServiceDate: {
      get() {
        return this.$store.state.orderInfo.guaranteedServiceDate;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'guaranteedServiceDate', value: value });
      }
    },
    shippingNotes: {
      get() {
        return this.$store.state.orderInfo.shippingNotes;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'shippingNotes', value: value });
      }
    },
    arrangedInstructions: {
      get() {
        return this.$store.state.orderInfo.arrangedInstructions;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'arrangedInstructions', value: value });
      }
    },
    pickupPhone: {
      get() {
        return this.$store.state.orderInfo.pickupPhone;
      },
      set(value) {
        this.$store.commit('setOrderInfo', { key: 'pickupPhone', value: value });
      }
    },
    pickupDatetime: {
      get() {
        return this.$store.state.orderInfo.pickupDatetime;
      },
      set(value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        this.$store.commit('setOrderInfo', { key: 'pickupDatetime', value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` });
      }
    },
    pickupDates() {
      let days = [];
      for (let i = 2; i < 25; i++) {
        let nextDay = new Date();
        nextDay.setDate(new Date().getDate() + i);
        console.log(nextDay.toISOString().slice(0, 10) + ' '+ nextDay.getDay());
        if (nextDay.getDay() != 0 && nextDay.getDay() != 6) {
          console.log('adding '+nextDay.getDay());
          let day = nextDay.getDate();
          let month = nextDay.getMonth() + 1;  // JavaScript's getMonth() method returns a zero-based index for months.
          let year = nextDay.getFullYear().toString().substr(-2); // Get last two digits of year
          
          days.push(`${month}/${day}/${year}`);  // ISO string (YYYY-MM-DD)
        }
      }
      return days;
    },
    pickupTimes() {
      var times = [];
      var startTime = new Date(); // start at today's date
      startTime.setHours(9, 0, 0, 0); // set time to 7:30 AM

      var endTime = new Date(); 
      endTime.setHours(12, 0, 0, 0); // set time to 3:30 PM

      while(startTime <= endTime){
        var hour = startTime.getHours();
        var minutes = startTime.getMinutes();

        // Pad the hours and minutes with a zero if needed to get a double digit string
        var timeString = (hour < 10 ? '0' : '') + hour + ':' + (minutes < 10 ? '0' : '') + minutes;
        times.push(timeString);

        // Add 30 minutes
        startTime.setMinutes(startTime.getMinutes() + 30);
      }
      return times;
    }
  },
  methods: {
    ...GlobalMethods,
    displayDate(isoDate) {
      let date = new Date(isoDate);
      let day = date.getDate();
      let month = date.getMonth() + 1;  // JavaScript's getMonth() method returns a zero-based index for months.
      let year = date.getFullYear().toString().substr(-2); // Get last two digits of year
      return `${month}/${day}/${year}`;
    },
    convertMilitaryTime(time) {
      let hours = time.split(':')[0];
      let minutes = time.split(':')[1];
      const suffix = hours >= 12 ? 'PM' : 'AM';
      // Convert from 24h time to 12h time format
      hours = hours % 12 || 12;
      // Zero padding for single digit hours
      hours = hours < 10 ? '0' + hours : hours;
      return `${hours}:${minutes} ${suffix}`;
    },
    getFedexRates() {
      Api.post("shipping/fedex", { shippingAddress: this.$store.getters.orderShippingAddress, items: this.$store.getters.cartShippingItems }).then(response => {
        if (response.length == 0) { this.shippingRates.fedexEconomy = {}; }
        if (response.success == 1) {
          this.shippingRates.fedexEconomy = {
            name: 'Fedex Economy',
            price: this.addPalletCharge(response.rates['FEDEX_FREIGHT_ECONOMY'].price),
            time: response.rates['FEDEX_FREIGHT_ECONOMY'].time + '-' + (parseInt(response.rates['FEDEX_FREIGHT_ECONOMY'].time)+2) + ' business days',
            shippingDetails: response.rates,
            quoteNumber: response.rates['FEDEX_FREIGHT_ECONOMY']['quote_number']
          };
          this.shippingRates.fedexPriority = {
            name: 'Fedex Priority',
            price: this.addPalletCharge(response.rates['FEDEX_FREIGHT_PRIORITY'].price),
            time: response.rates['FEDEX_FREIGHT_PRIORITY'].time + '-' + (parseInt(response.rates['FEDEX_FREIGHT_PRIORITY'].time)+2) + ' business days',
            shippingDetails: response.rates,
            quoteNumber: response.rates['FEDEX_FREIGHT_PRIORITY']['quote_number']
          };
        } else {
          this.shippingRates.fedexEconomy = {
            name: 'Fedex Economy',
            message: response.message
          };
          this.shippingRates.fedexPriority = {
            name: 'Fedex Priority',
            message: response.message
          };
        }
      });
    },
    getFedexGroundRates() {
      Api.post("shipping/fedex-ground", { shippingAddress: this.$store.getters.orderShippingAddress, items: this.$store.getters.cartShippingItems }).then(response => {
        if (response.success == 1) {
          let price = response.rates['FEDEX_GROUND'].price;
          if (!this.cartHasOnlyPouches()) {
            price = this.addGroundCharge(price);
          }
          this.shippingRates.fedexGround = {
            name: 'Fedex Ground',
            price: price,
            time: response.rates['FEDEX_GROUND'].time + '-' + (parseInt(response.rates['FEDEX_GROUND'].time)+2) + ' business days',
            shippingDetails: response.rates,
            quoteNumber: response.rates['FEDEX_GROUND']['quote_number']
          };
        } else {
          this.shippingRates.fedexGround = {
            name: 'Fedex Ground',
            message: response.message
          };
        }
      });
    },
    getRoadRunnerRates() {
      Api.post("shipping/roadrunner", { shippingAddress: this.$store.getters.orderShippingAddress, items: this.$store.getters.cartShippingItems }).then(response => {
        if (response.success == 1) {
          let plusTenDays = parseInt(response.rates.RoutingInfo.EstimatedTransitDays)+parseInt(10);
          this.shippingRates.roadRunner = {
            name: 'Roadrunner',
            price: this.addPalletCharge(response.rates.NetCharge),
            time: response.rates.RoutingInfo.EstimatedTransitDays + '-' + plusTenDays +' business days',
            shippingDetails: response.rates,
            quoteNumber: response.rates.quoteNumber
          };
        } else {
          this.shippingRates.roadRunner = {
            name: 'Roadrunner',
            message: response.message
          };
        }
      });
    },
    getOldDominionRates() {
      Api.post("shipping/old-dominion", { shippingAddress: this.$store.getters.orderShippingAddress, items: this.$store.getters.cartShippingItems }).then(response => {
        if (response.success == 1) {
          let plusSevenDays = parseInt(response.rates.destinationCities.serviceDays)+parseInt(2);
          if (response.rates.rateEstimate.netFreightCharge == 0) {
            this.shippingRates.oldDominion = null;
          } else {
            this.shippingRates.oldDominion = {
              name: 'Old Dominion',
              price: this.addPalletCharge(response.rates.rateEstimate.netFreightCharge),
              time: response.rates.destinationCities.serviceDays + '-' + plusSevenDays +' business days',
              shippingDetails: response.rates,
              quoteNumber: response.rates.referenceNumber
            };
          }
        } else {
          this.shippingRates.oldDominion = {
            name: 'Old Dominion',
            message: response.message
          };
        }
      });
    },
    getXPORates() {
      Api.post("shipping/xpo", { shippingAddress: this.$store.getters.orderShippingAddress, items: this.$store.getters.cartShippingItems }).then(response => {
        if (response.success == 1) {
          this.shippingRates.xpo = {
            name: 'XPO',
            price: this.addPalletCharge(response.rates.rateQuote.totCharge[0].amt),
            time: response.rates.transitTime.transitDays+'-'+(parseInt(response.rates.transitTime.transitDays)+2)+' business days',
            shippingDetails: response.rates,
            quoteNumber: response.rates.rateQuote.confirmationNbr
          };
        } else {
          this.shippingRates.xpo = {
            name: 'XPO',
            message: response.message
          };
        }
      });
    },
    getShippingRates() {
      // Make sure form is validate before proceeding
			if (this.existingAddress == false && !this.$refs.form.checkValidity()) {
				this.$refs.form.reportValidity();
        return false;
      }
      
      this.$overlay(true, '<div>Finding the best shipping prices for your order.</div>', 7000);
      setTimeout(() => {
        this.foundRates = null;
        Object.keys(this.shippingRates).forEach(key => {
          if (this.shippingRates[key] != null) {
            this.foundRates = true;
          }
        });
        if (this.foundRates == null) {
          this.addressComplete = false;
          this.$overlay(true, 'We could not find rates for your shipping address. Please check your address and try again.', 3500);
        }
      }, 7000);

      this.getFedexRates();
      // this.getRoadRunnerRates();
      this.getOldDominionRates();
      this.getXPORates();

      if (this.$store.getters.typeTotals.boxes <= 3 && this.$store.getters.typeTotals.drums == 0 && this.$store.getters.typeTotals.totes == 0) {
        this.getFedexGroundRates();
      }

      this.addressComplete = true;

      document.querySelector("#fermentation-products #nav").scrollIntoView({
        behavior: 'smooth'
      });
    },
    formatDate(value) {
      let date = moment(value).format('MM/D/YY');
      return date;
    },
    addressSelected(address) {
      this.$store.commit('setShippingAddress', address);
    },
    clearExistingAddress() {
      this.newShippingAddress = {
        id: '',
        name: this.$store.state.userData.firstName+' '+this.$store.state.userData.lastName,
        company: this.$store.state.userData.displayName,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'US',
        phone: '',
        type: 'shipping',
        residential: 0
      };
    },
    addPalletCharge(price) {
      price = this.addResidentialCharge(price);
      return parseFloat(parseFloat(price) + parseFloat(this.$store.getters.pallets * 20)).toFixed(2);
    },
    addGroundCharge(price) {
      // price = this.addResidentialCharge(price);
      return parseFloat(parseFloat(price) + parseFloat(10)).toFixed(2);
    },
    addResidentialCharge(price) {
      if (this.$store.getters.orderShippingAddress.residential == '1') {
        return parseFloat(parseFloat(price) + parseFloat(140)).toFixed(2);
      }
      return price;
    },
    saveNewAddress() {
      let newAddress = { customer_id: this.$store.state.userData.id, ...this.newShippingAddress, shipping_destination: this.shippingDestination };
      Api.postAuth('account/address', newAddress, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$store.commit('getUserAddresses');
          this.$store.commit('setShippingAddress', response);
        }
      });
    },
    cartHasOnlyGear() {
      let notGear = this.$store.state.cartItems.filter(cartItem => cartItem.post_type != 'gear');
      if (notGear.length > 0) {
        return false;
      }
      return true;
    },
    cartHasOnlySamples() {
      let notSample = this.$store.state.cartItems.filter(cartItem => cartItem.post_type != 'sample');
      if (notSample.length > 0) {
        return false;
      }
      return true;
    },
    cartHasOnlyPouches() {
      let notPouch = this.$store.state.cartItems.filter(cartItem => cartItem.type != 'pouch');
      if (notPouch.length > 0) {
        return false;
      }
      return true;
    }
  },
  beforeMount() {
    this.checkOrderProgress();
  },
  mounted() {
    this.$store.commit('getUserAddresses');
    this.twoDaysDate = moment().add(2, 'days').toISOString();
    this.newShippingAddress.name = this.$store.state.userData.firstName+' '+this.$store.state.userData.lastName;
    this.newShippingAddress.company = this.$store.state.userData.displayName;
  }
}
</script>

<style lang="scss" scoped>

</style>